class ZIDXLandingPage implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxLandingPageContainer";
    }
    getMatchURL(){
        return "/page/";
    }
    render(){
        // console.log("page edit");
    }
}